html,
body {
  padding: 0;
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
}

html,
body {
  scroll-behavior: smooth;
  scroll-snap-type: y;
}

@media (max-width: 800px) {
  html,
  body {
    overflow-x: hidden;
  }
}

* {
  box-sizing: border-box;
}
